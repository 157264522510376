import * as React from "react";
import "../styles/styles.scss";

const phoneNumber = "734-531-9445";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>DRsprayers</title>
      <h1>Downriver Sprayer Repair</h1>
      <a href="tel:7345319445">
        <h2 className="number">{phoneNumber}</h2>
      </a>
      <h2>We repair commercial paint sprayers and pressure washers</h2>
      <h2>(and other small engine repair, too!)</h2>

      <h3 className="price">$40 full diagnostic</h3>
      <h3 className="price">$70/hourly</h3>
      <h2 className="warning">(this includes cleaning it enough to make repairs possible if brought in dirty!)</h2>
      <a href="tel:7345319445">
         <h2 className="number">{phoneNumber}</h2>
      </a>
    </main>
  )
}

export default IndexPage